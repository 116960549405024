:root {
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-18: 18px;
  --size-20: 20px;
  --size-22: 22px;
  --size-27: 27px;
  --size-24: 24px;
  --size-30: 30px;
  --size-32: 32px;
  --size-38: 38px;
  --size-40: 40px;
  --size-42: 42px;
  --size-48: 48px;
  --size-54: 54px;
  --size-56: 56px;
  --size-58: 58px;
  --size-64: 64px;
  --size-74: 74px;
  --size-80: 80px;
  --size-96: 96px;
  --size-103: 103px;
  --size-120: 120px;
  --size-134: 134px;

  --size-container-max-width-sm: 720px;
  --size-container-max-width-md: 864px;
  --size-container-max-width-lg: 1240px;
  --size-container-max-width-xl: 1280px;

  --size-header-height-sm: 60px;
  --size-header-height-md: 75px;
}
