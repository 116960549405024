:root {
  --font-family-base: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-heading: 'Produkt', Georgia, Times, 'Times New Roman', serif;

  --font-weight-light: 300;
  --font-weight-base-400: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 800;
  --font-weight-super: 1000;

  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-42: 42px;
  --font-size-48: 48px;
  --font-size-52: 52px;
  --font-size-72: 72px;
  --font-size-82: 82px;

  --line-height-heading: 1;
  --line-height-list: 1.5;
  --line-height-benefits: 1.3;
  --line-height-navigation: 0;
  --line-height-button: 0;
}
